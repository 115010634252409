import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { IconOperationOpenInNew } from '@bcmi-labs/react-icons';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import LanguageSelector from '../../components/languageSelector';

import './guideHomepageTemplate.scss';

export default function Template({ data, pageContext }) {
  const { mdx } = data; // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx;
  const { locale: currentLocale, availableLocales } = pageContext;
  const { t } = useTranslation();

  return (
    <Layout currentLocale={currentLocale}>
      <SEO title={frontmatter.title} description={frontmatter.description} image={frontmatter.featuredImage} />
      {availableLocales.length > 1 ? (
        <LanguageSelector locales={availableLocales} currentLocale={currentLocale} />
      ) : null}
      <div className="ino-docs-banner">
        <div className="ino-docs-banner__container">
          <div className="ino-docs-banner__left">
            <div className="ino-docs-banner__title">Check out Arduino Docs!</div>
            <div className="ino-docs-banner__description">
              There’s a brand new site for official Arduino documentation, with almost 200 new tutorials, an improved
              getting started experience and product pages with all the technical info.
            </div>
            <div className="ino-docs-banner__buttons">
              <a href="//docs.arduino.cc/" className="ino-docs-banner__link">
                ARDUINO DOCS <IconOperationOpenInNew />
              </a>
            </div>
          </div>
          <div className="ino-docs-banner__right" />
        </div>
      </div>
      <main className="guide-homepage page">
        <div className="main-header">
          <h2>{frontmatter.title}</h2>
        </div>
        <section>
          <MDXRenderer>{body}</MDXRenderer>
        </section>
        <div className="main-footer">
          {t('common:lastRevision')} {frontmatter.date}
        </div>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      tableOfContents(maxDepth: 4)
      frontmatter {
        date
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fixed(width: 1200, height: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
